<template>
    <div class="BoothAdvertising">
        <div class="BoothAdvertisingHeader">
            <el-breadcrumb separator="/">
<!--                <el-breadcrumb-item>展位</el-breadcrumb-item>-->
                <el-breadcrumb-item>推荐引擎推广</el-breadcrumb-item>
                <el-breadcrumb-item>推广管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="promotion-txt">
            <span>所有推广计划 
                {{ cycleNumber==1?'(第一周期)':cycleNumber==2?'(第二周期)':cycleNumber==3?'(第三周期)':'' }}
            </span>
            <span>推广余额：<b>￥</b>{{amount}}</span>
        </div>
        <div class="promotion-plan">
            <el-tabs v-model="activeName" @tab-click="changeTab">
                <el-tab-pane label="推广计划" name="promotionPlan">
                    <div class="promotion-mid" v-show="tabType=='promotionPlan'">
                        <div class="tableBtn header">
                          <div class="tableBtn">  <router-link :to="{name:'PlanStepOne'}"><el-button class="btn1">新建图片计划</el-button></router-link>
                            <el-button class="btn2" @click="deleSelectData">删除</el-button>
                            <el-button class="btn3" @click="overPromotion" :disabled="leftCount === 0">结束推广</el-button>
                            <el-tag :type="elTagValue" v-if="isCompetition" style="margin:0 10px; ">结束推广剩余次数：<b style="font-weight: bold; font-size: 16px;">{{leftCount}}</b></el-tag>
                            <el-button class="btn1" @click="createPlanGroup">新建计划组</el-button></div>
                            <div>
                                <el-select v-if="cycleNumber>1" style="margin-right:20px;width:120px" v-model="perioValue" placeholder="请选择" @change="selectPerio">
                                    <el-option
                                    v-for="item in perioValueoptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            <el-button class="btn4" @click="nextCycle" :class="{'noEnd':!isEndExtend ||cycleNumber==3}" :disabled="cycleNumber==3">确认提交并进入下一个周期</el-button>

                            </div>
                        </div>
                        <div class="promotion-table">
                            <el-table :data="periodData" @selection-change="handleSelectionChange" border style="width: 100%;flex: 1; margin-top: 20px;height: calc(100vh - 450px);overflow-y: auto" :cell-style="{color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}" :header-cell-style="{fontWeight: '500', color: '#14141C', background: '#F5F5F5',height: '48px'}">
                                <el-table-column :resizable="false" type="selection" width="50" align="center"></el-table-column>
                                <el-table-column :resizable="false" label="推广计划" prop="name" width="220">
                                    <template slot-scope="scope">
                                        <span v-if="isShow!==scope.row.id" ref="spanShow" @click="clickName(scope.row)" class="plan-name">
                                            {{scope.row.name}}
                                            <i class="iconfont edit-plan-iocn">&#xe615;</i>
                                        </span>
                                        <input type="text"
                                               name="nameInput"
                                               id="nameInput"
                                               :ref="`nameInput_${scope.row.id}`"
                                               v-model="scope.row.name"
                                               v-show="isShow === scope.row.id"
                                               @blur="validateNum(scope.row.name,scope.row.id)"
                                               @change="inputTargetValue(scope.row)"
                                        >
<!--                                        <span class="editTxt" style="color: #1E63F1" v-show="!scope.row.isEditInput">{{scope.row.name}}</span>-->
<!--                                        <el-input v-model="scope.row.name" style="width: 120px;" v-show="scope.row.isEditInput" :value="scope.row.name"></el-input>-->
<!--                                        <i style="color: #1E63F1" @click="scope.row.isEditInput = !scope.row.isEditInput" class="iconfont editIcon">&#xe615;</i>-->
                                    </template>
                                </el-table-column>
                                <el-table-column :resizable="false" label="推广计划组" width="95" prop="show_count">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.group_name}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :resizable="false" label="时段 | 实时折扣系数" width="220">
                                    <template slot-scope="scope">
                                        <span style="color: #1E63F1;cursor: pointer" @click="editTimeDiscount(scope.row.id)">{{scope.row.time_interval}} | {{scope.row.real_discount_ratio}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :resizable="false" label="限额" prop="budget">
                                    <template slot-scope="scope">
                                        <span style="color: #1E63F1;cursor: pointer" @click="editBudget(scope.row.id,scope.row.budget)">{{scope.row.budget ? scope.row.budget : '不限'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :resizable="false" label="展现量" prop="budget">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.show_count ? scope.row.show_count : '-'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :resizable="false" label="点击量" prop="budget">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.show_count ? scope.row.click : '-'}}</span>
                                    </template>
                                </el-table-column>
<!--                                <el-table-column :resizable="false" label="转化量" prop="show_count">-->
                                <el-table-column :resizable="false" label="成交量" prop="show_count">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.show_count ? scope.row.conversion : '-'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :resizable="false" label="点击率（%）" prop="conversion" width="120">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.click_rate}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :resizable="false" width="140" label="平均点击率（%）" prop="conversion">
                                    <template slot-scope="scope">
                                        <!-- <span>{{scope.row.avg_click_rate}}</span> -->
                                        <span>{{scope.row.click_rate}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :resizable="false" label="转化率（%）" prop="conversion_rate" width="120">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.conversion_rate}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :resizable="false" width="140" label="平均转化率（%）" prop="conversion_rate" >
                                    <template slot-scope="scope">
                                        <!-- <span>{{scope.row.avg_conversion_rate}}</span> -->
                                        <span>{{scope.row.conversion_rate}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :resizable="false" label="花费" prop="total_cost">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.total_cost ? scope.row.total_cost : '-'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :resizable="false" width="100" label="操作" prop="name">
                                    <template slot-scope="scope">
                                        <el-button type="text" @click="editData(scope.row.id)">编辑</el-button>
                                        <el-button type="text" @click="deleData(scope.row.id)" style="color: #FD4446">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div style="display: flex;justify-content: space-between;margin-top: 24px">
                                <span></span>
                                <el-pagination
                                        class="operation-pagination"
                                        style="margin-top: 0"
                                        :current-page="adminPages.currentPageNum"
                                        :page-size="adminPages.eachPageNum"
                                        layout="sizes, prev, pager, next, total, jumper"
                                        :total="adminPages.total"
                                        @current-change="adminCurrentChange"
                                ></el-pagination>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="资源位" name="sourceData">
                    <div class="promotion-mid" v-show="tabType=='sourceData'">
                        <!-- <div class="tableBtn">
                            <router-link :to="{name:'PlanStepOne'}"><el-button class="btn1">新建图片计划</el-button></router-link>
                            <el-button class="btn2" @click="deleSelectData">删除</el-button>
                            <el-button class="btn3" @click="overPromotion" :disabled="leftCount === 0">结束推广</el-button>
                            <el-tag :type="elTagValue" v-if="isCompetition" style="margin-left: 10px;">结束推广剩余次数：<b style="font-weight: bold; font-size: 16px;">{{leftCount}}</b></el-tag>
                        </div> -->
                        <div class="promotion-table">
                            <el-table :data="sorceTableData" @selection-change="handleSelectionChange" border style="width: 100%;flex: 1; margin-top: 20px;height: calc(100vh - 450px);overflow-y: auto" :cell-style="{color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}" :header-cell-style="{fontWeight: '500', color: '#14141C', background: '#F5F5F5',height: '48px'}">
                                <el-table-column :resizable="false" type="selection" width="50" align="center"></el-table-column>
                                <el-table-column :resizable="false"  label="资源位" prop="name" width="220">

                                    <template slot-scope="scope">
                                        <span v-if="isShow!==scope.row.id" style="color: #FD4446" ref="spanShow" @click="clickName(scope.row)" class="plan-name">
                                            {{scope.row.name}}
                                            <i class="iconfont edit-plan-iocn">&#xe615;</i>
                                        </span>
                                        <input type="text"
                                               name="nameInput"
                                               id="nameInput"
                                               :ref="`nameInput_${scope.row.id}`"
                                               v-model="scope.row.name"
                                               v-show="isShow === scope.row.id"
                                               @blur="validateNum(scope.row.name,scope.row.id)"
                                               @change="inputTargetValue(scope.row)"
                                        >
<!--                                        <span class="editTxt" style="color: #1E63F1" v-show="!scope.row.isEditInput">{{scope.row.name}}</span>-->
<!--                                        <el-input v-model="scope.row.name" style="width: 120px;" v-show="scope.row.isEditInput" :value="scope.row.name"></el-input>-->
<!--                                        <i style="color: #1E63F1" @click="scope.row.isEditInput = !scope.row.isEditInput" class="iconfont editIcon">&#xe615;</i>-->
                                    </template>
                                </el-table-column>

                                <el-table-column :resizable="false" label="展现量" prop="budget">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.show_count ? scope.row.show_count : '-'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :resizable="false" label="点击量" prop="budget">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.show_count ? scope.row.click : '-'}}</span>
                                    </template>
                                </el-table-column>
<!--                                <el-table-column :resizable="false" label="转化量" prop="show_count">-->
                                <el-table-column :resizable="false" label="成交量" prop="show_count">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.show_count ? scope.row.conversion : '-'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :resizable="false" label="点击率（%）" prop="conversion" width="120">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.click_rate}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :resizable="false" label="转化率（%）" prop="conversion_rate" width="120">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.conversion_rate}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :resizable="false" label="花费" prop="total_cost">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.total_cost ? scope.row.total_cost : '-'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :resizable="false" label="操作" prop="name">
                                    <template slot-scope="scope">
                                        <el-button type="text">编辑</el-button>
                                        <el-button type="text" style="color: #FD4446">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div style="display: flex;justify-content: space-between;margin-top: 24px">
                                <span></span>
                                <el-pagination
                                        class="operation-pagination"
                                        style="margin-top: 0"
                                        :current-page="sourcePages.currentPageNum"
                                        :page-size="sourcePages.eachPageNum"
                                        layout="sizes, prev, pager, next, total, jumper"
                                        :total="sorceTableData.length"
                                        @current-change="sourceCurrentChange"
                                ></el-pagination>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="人群定向" name="peopleData">
                    <crowd-orientation></crowd-orientation>
                </el-tab-pane>
            </el-tabs>
        </div>
        <el-drawer
                :wrapperClosable="false"
                size="50%"
                title="自定义预算"
                :with-header="false"
                :visible.sync="drawer"
                direction="rtl">
            <i style="position: absolute;right: 30px;top: 30px;font-size: 30px;cursor: pointer" @click="drawer = false" class="el-icon-close"></i>
            <div class="CustomBudgetBox">
                <span class="title">自定义预算</span>
                <span>所有日预算修改后，立即生效。</span>
                <span>统一日预算：从当日起，至未来所有提案数内均执行该预算。</span>
                <span>您可以编辑此次的日预算，输入的范围为：50-9999999，只能输入整数； 若您希望日预算为不限，则可输入0，即为不限。</span>
                <span>请注意各单元的目标消耗总和不要超过计划日预算，否则会影响消耗优化的功能效果</span>
                <div class="box-bottom">
                    <span>统一日预算（￥）</span>
                    <el-input style="width: 380px" placeholder="不限" v-model="yusuan"></el-input>
                    <el-button style="margin-left: 20px" @click="confirmBudget">确定</el-button>
                </div>
                <div style="margin-top: 16px;font-size: 14px;color: red" v-if="yusuan == 0">提示:如果将日预算设置为不限预算，投放策略将为尽快投放！</div>
            </div>
        </el-drawer>
        <el-drawer
                :wrapperClosable="false"
                size="70%"
                title="时间设置"
                :with-header="false"
                :visible.sync="drawer2"
                direction="rtl">
            <i style="position: absolute;right: 30px;top: 30px;font-size: 30px;cursor: pointer" @click="drawer2 = false" class="el-icon-close"></i>
            <div class="CustomBudgetBox" style="height: calc(100vh - 100px);overflow-y: auto">
                <span class="title">时间设置</span>
                <span>分时折扣设置</span>
                <span>1.分时段折扣功能允许您对不同时段设置不同的折扣出价，折扣出价计算公式为：X% * 出价（X为时段折扣系数），100%即为无折扣。</span>
                <span>2.鼠标悬浮于某个时间段即可查看该时间段的折扣出价。设置折扣出价时支持鼠标点选某个时间段或者框选多个时间段。</span>
                <div class="CustomBudget-radio" style="margin-top: 20px">
                    <el-radio-group v-model="radio4" style="margin-left: 20px;margin-top: -2px" @change="selectShowWay">
                        <el-radio :label="1">整天展示</el-radio>
                        <el-radio :label="2">自定义</el-radio>
                    </el-radio-group>
                </div>
                <div class="CustomBudget-table">
                    <div class="CustomBudget-table-header">

                    </div>
                    <el-table :data="tableData2" border style="width: 100%;flex: 1;margin-top: 20px" :cell-style="{height: '60px',color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}" :header-cell-style="{fontWeight: '500', color: '#14141C', background: '#F8F8F8',height: '60px'}">
                        <el-table-column label="" prop="title" width="120" align="center"></el-table-column>
                        <el-table-column class-name="table-td-item" label="00:00-06:00" prop="06" align="center">
                            <template slot-scope="scope">
                                <div class="table-cell" style="display: flex;justify-content: flex-start;align-items: center">
                                    <div :class="oneToSix[index].launch === 1 ? 'isShowActive' : ''" v-for="(item,index) in oneToSix" style="flex: 1;border: 1px solid #EEE;cursor: pointer;height: 36px" @click="clickDialog(item, $event)" @mouseleave="closeHoverD(item, $event)"  @mouseenter="hoverDialog(item, $event)">
                                        <div></div>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="06:00-12:00" prop="612" align="center">
                            <template slot-scope="scope">
                                <div class="table-cell" style="display: flex;justify-content: flex-start;align-items: center">
                                    <div :class="sixToTwelve[index].launch === 1 ? 'isShowActive' : ''" v-for="(item,index) in sixToTwelve" style="flex: 1;border: 1px solid #EEE;cursor: pointer;height: 36px" @click="clickDialog(item, $event)" @mouseleave="closeHoverD(item, $event)"  @mouseenter="hoverDialog(item, $event)">
                                        <div></div>

                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="12:00-18:00" prop="1218" align="center">
                            <template slot-scope="scope">
                                <div class="table-cell" style="display: flex;justify-content: flex-start;align-items: center">
                                    <div :class="TwelveToEighteen[index].launch === 1 ? 'isShowActive' : ''" v-for="(item,index) in TwelveToEighteen" style="flex: 1;border: 1px solid #EEE;cursor: pointer;height: 36px" @click="clickDialog(item, $event)" @mouseleave="closeHoverD(item, $event)"  @mouseenter="hoverDialog(item, $event)">
                                        <div></div>

                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="18:00-24:00" prop="1824" align="center">
                            <template slot-scope="scope">
                                <div class="table-cell" style="display: flex;justify-content: flex-start;align-items: center">
                                    <div :class="EighteenToTwentyFour[index].launch === 1 ? 'isShowActive' : ''" v-for="(item,index) in EighteenToTwentyFour" style="flex: 1;border: 1px solid #EEE;cursor: pointer;height: 36px" @click="clickDialog(item, $event)" @mouseleave="closeHoverD(item, $event)"  @mouseenter="hoverDialog(item, $event)">
                                        <div></div>

                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <span>温馨提示：默认无折扣100%，可以拖拽鼠标选择投放时间段并自定义折扣。</span>
                <div style="margin-top: 360px;display: flex;justify-content: space-between"><span></span><el-button @click="closeDrawer2" style="background: #FD4446;color: #ffffff;border-color: #FD4446;width: 120px;font-size: 20px">确定</el-button></div>
            </div>
        </el-drawer>
        <div ref="hoverWrapper" v-show="drawer2" class="hoverWrapper" :style="hoverFormData.style" v-if="hoverFormData.show">
            <p>时段{{hoverFormData.timezone}}</p>
            <p>{{hoverFormData.discount}}%折扣</p>
        </div>
        <div ref="clickWrapper" v-show="drawer2" class="clickWrapper" :style="clickFormData.style" v-if="clickFormData.show">
            <p>时段：{{clickFormData.type}}</p>
            <el-radio-group v-model="radio5">
                <el-radio :label="1">自定义</el-radio>
                <span class="customInput"><el-input oninput="value=value.replace(/[^\d]/g,'')" :disabled="radio5 === 1 ? isDisabled : !isDisabled" v-model="clickFormData.discount" style="width: 130px;" placeholder="30~500的整数"></el-input> <span>%</span></span>
                <el-radio :label="2">无折扣</el-radio>
                <el-radio :label="3">不投放</el-radio>
            </el-radio-group>
            <div class="clickWrapperBtn">
                <el-button @click="clickFormData.show = false">取消</el-button>
                <el-button @click="setTime(clickFormData.name,clickFormData.index,clickFormData.discount)" style="background-color: #FD4446;border-color: #FD4446;color: #ffffff">确定</el-button>
            </div>
        </div>
        <!-- 新建计划组弹框 -->
        <el-dialog
            title="新建计划组"
            :visible.sync="planGroupVisible"
            width="30%">
            <el-input v-model="planName" placeholder="请输入计划组名称"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialogVisible">取 消</el-button>
                <el-button type="primary" @click="confirmAddPlanGroup">确 定</el-button>
            </span>
            </el-dialog>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import CrowdOrientation from "@/components/student/operationPromotion/shopDrainage/CrowdOrientation.vue";
    export default {
        name: "PromotionManagement",
        components: {CrowdOrientation},
        data(){
            return {
                amount:null,
                activeName:'promotionPlan',
                isShow: null,
                radio4:1,
                radio5:0,
                isDisabled:false,
                tableData:[],
                tableData2:[{
                    title:'时段',
                    '06':'1',
                    '612':'',
                    '1218':'',
                    '1824':'',
                }],
                oneToSix: [
                    {"type":0,"discount":100,"launch":1,'timezone':'00:00-01:00','name':'oneToSix','index':0},
                    {"type":1,"discount":100,"launch":1,'timezone':'01:00-02:00','name':'oneToSix','index':1},
                    {"type":2,"discount":100,"launch":1,'timezone':'02:00-03:00','name':'oneToSix','index':2},
                    {"type":3,"discount":300,"launch":1,'timezone':'03:00-04:00','name':'oneToSix','index':3},
                    {"type":4,"discount":100,"launch":1,'timezone':'04:00-05:00','name':'oneToSix','index':4},
                    {"type":5,"discount":100,"launch":1,'timezone':'05:00-06:00','name':'oneToSix','index':5},
                ],
                sixToTwelve: [
                    {"type":6,"discount":100,"launch":1,'timezone':'06:00-07:00','name':'sixToTwelve','index':0},
                    {"type":7,"discount":100,"launch":1,'timezone':'07:00-08:00','name':'sixToTwelve','index':1},
                    {"type":8,"discount":100,"launch":1,'timezone':'08:00-09:00','name':'sixToTwelve','index':2},
                    {"type":9,"discount":100,"launch":1,'timezone':'09:00-10:00','name':'sixToTwelve','index':3},
                    {"type":10,"discount":100,"launch":1,'timezone':'10:00-11:00','name':'sixToTwelve','index':4},
                    {"type":11,"discount":100,"launch":1,'timezone':'11:00-12:00','name':'sixToTwelve','index':5},
                ],
                TwelveToEighteen: [
                    {"type":12,"discount":100,"launch":1,'timezone':'12:00-13:00','name':'TwelveToEighteen','index':0},
                    {"type":13,"discount":100,"launch":1,'timezone':'13:00-14:00','name':'TwelveToEighteen','index':1},
                    {"type":14,"discount":100,"launch":1,'timezone':'14:00-15:00','name':'TwelveToEighteen','index':2},
                    {"type":15,"discount":100,"launch":1,'timezone':'15:00-16:00','name':'TwelveToEighteen','index':3},
                    {"type":16,"discount":100,"launch":1,'timezone':'16:00-17:00','name':'TwelveToEighteen','index':4},
                    {"type":17,"discount":100,"launch":1,'timezone':'17:00-18:00','name':'TwelveToEighteen','index':5},
                ],
                EighteenToTwentyFour: [
                    {"type":18,"discount":100,"launch":1,'timezone':'18:00-19:00','name':'EighteenToTwentyFour','index':0},
                    {"type":19,"discount":100,"launch":1,'timezone':'19:00-20:00','name':'EighteenToTwentyFour','index':1},
                    {"type":20,"discount":100,"launch":1,'timezone':'20:00-21:00','name':'EighteenToTwentyFour','index':2},
                    {"type":21,"discount":100,"launch":1,'timezone':'21:00-22:00','name':'EighteenToTwentyFour','index':3},
                    {"type":22,"discount":100,"launch":1,'timezone':'22:00-23:00','name':'EighteenToTwentyFour','index':4},
                    {"type":23,"discount":100,"launch":1,'timezone':'23:00-24:00','name':'EighteenToTwentyFour','index':5}
                ],
                hoverFormData: {
                    show: false,
                    type: null,
                    discount: null,
                    launch:null,
                    timezone:null,
                    name:null,
                    index:null,
                    style: ''
                },
                clickFormData: {
                    show: false,
                    discount:null,
                    type: null,
                    launch:null,
                    timezone:null,
                    name:null,
                    index:null,
                    style: ''
                },
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                sourcePages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                drawer:false,
                drawer2:false,
                yusuan:'',
                yusuanIndex:null,
                timeId:null,
                delectVal:[],
                leftCount: null,
                tabType:'promotionPlan',
                sorceTableData:[
                    {
                    name:'站外资源位-手机浏览类',
                    show_count:147640,
                    click:17716,
                    conversion:2478,
                    click_rate:12,
                    conversion_rate:14,
                    total_cost:4333.56
                },{
                    name:'站内资源位-PC焦点图',
                    show_count:73820,
                    click:5905,
                    conversion:354,
                    click_rate:8,
                    conversion_rate:6,
                    total_cost:2152.04
                },
            
            ],
            cycleNumber:1,//标识第几个周期
            playGroup:JSON.parse(localStorage.getItem('playGroup')) || [],//新建计划组数据，
            planGroupVisible:false,
            planName:'',
            selectCycle:[],
            isEndExtend:false,
            periodData:[],
            perioValueoptions:[{
                label:'第一周期',
                value:1
            },{
                label:'第二周期',
                value:2
            }],
            perioValue:''
            }
            
           
          
            
        },
        created() {
            this.getSoreDrainage();
            // if(localStorage.getItem('cycleNumber')){
            //     this.cycleNumber=localStorage.getItem('cycleNumber')
            // }else{
            //     localStorage.setItem('cycleNumber',1)
            // }
        },
        computed: {
            menus() {
                return this.$store.getters.dataMenuList;
            },
            isCompetition() {
                return this.$common.isCompetion() && this.leftCount !== null;
            },
            elTagValue() {
                if (this.leftCount > 0) {
                    return  'success';
                } else {
                    return 'info';
                }
            }
        },
        mounted(){
           if(localStorage.getItem('playGroup')){
           }else{
            localStorage.setItem('playGroup',JSON.stringify([]))
           }

           
        },
        methods: {
            ...mapActions([
                'setDataMenusList'
            ]),
            // 更新状态
            operation_updateStatus(index1, index2,  status) {
                let param = {
                    offset1: Number(index1),
                    offset2: Number(index2),
                    status: Number(status),
                }
                this.$http.axiosGetBy(this.$api.data_setUserOpList, param, (res) => {
                    if (res.code === 200) {
                        this.setDataMenusList();
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            getSoreDrainage(){
                let param = {
                    page:this.adminPages.currentPageNum,
                    paging:1,
                    pageSize:this.adminPages.eachPageNum
                }
                this.$http.axiosGetBy(this.$api.storeDrainage, param, res=>{
                    if(res.code === 200){

                        this.periodData=[]
                        this.tableData = res.data.data;
                        if(localStorage.getItem('cycleNumber')){
                            this.cycleNumber=localStorage.getItem('cycleNumber')
                        }else{
                            if(this.tableData.length>0){
                                this.tableData.forEach(item=>{
                                    this.cycleNumber=item.period>this.cycleNumber?item.period:this.cycleNumber
                                })
                                
                            }else{
                                localStorage.setItem('cycleNumber',1)
                            }
                        }
                        
                        if(this.cycleNumber==3){
                                this.perioValueoptions=[{
                                    label:'第一周期',
                                    value:1
                                },{
                                    label:'第二周期',
                                    value:2
                                },{
                                    label:'第三周期',
                                    value:3
                                }]
                            }
                            this.tableData.forEach(item=>{
                                if(item.period==this.cycleNumber){
                                    this.periodData.push(item)
                                }
                            })
                      

                        console.log(res.data.data);
                        this.adminPages.total = res.data.total;
                        this.amount = res.data.amount;
                        if (res.data.count !== undefined) {
                            this.leftCount = Number(res.data.count);
                            this.$lockr.set('zw_count', this.leftCount);
                        }

                        // this.$message.success(res.msg);
                        if (this.$common.isCompetion()) {
                            let menus = this.menus;
                            let currentStatus = menus[2].children[2].status;
                            if (Number(currentStatus) === 0) {
                                this.operation_updateStatus(2, 2, 1);
                            }
                            if (this.leftCount !=3 && currentStatus==1 && this.cycleNumber==3) {
                                this.operation_updateStatus(2, 2, 2);
                            }
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            setTime(name,index,discount){
                let oneToSixStatus = 0;
                let sixToTwelveStatus = 0;
                let TwelveToEighteenStatus = 0;
                let EighteenToTwentyFourStatus = 0;
                if(name == 'oneToSix'){
                    if(this.radio5 == 1){
                        if(discount < 1){
                            this.$message.warning('折扣不能设置为0！');
                            return
                        }
                        this.oneToSix[index].discount = discount;
                        this.oneToSix[index].launch = 1
                    } else if(this.radio5 == 2){
                        this.oneToSix[index].launch = 1
                        this.oneToSix[index].discount = 100;
                    } else if(this.radio5 == 3){
                        this.oneToSix[index].launch = 0;
                        this.oneToSix[index].discount = 0;
                    }
                    if(this.radio4 === 1){
                        this.oneToSix.map(item=>{
                            if(item.discount === 0){
                                oneToSixStatus = 1
                            }
                        })
                    }
                } else if(name == 'sixToTwelve'){
                    if(this.radio5 == 1){
                        if(discount < 1){
                            this.$message.warning('折扣不能设置为0！');
                            return
                        }
                        this.sixToTwelve[index].discount = discount;
                        this.sixToTwelve[index].launch = 1
                    } else if(this.radio5 == 2){
                        this.sixToTwelve[index].launch = 1
                        this.sixToTwelve[index].discount = 100;
                    } else if(this.radio5 == 3){
                        this.sixToTwelve[index].launch = 0;
                        this.sixToTwelve[index].discount = 0;
                    }
                    if(this.radio4 === 1){
                        this.sixToTwelve.map(item=>{
                            if(item.discount === 0){
                                sixToTwelveStatus = 1
                            }
                        })
                    }
                } else if(name == 'TwelveToEighteen'){
                    if(this.radio5 == 1){
                        if(discount < 1){
                            this.$message.warning('折扣不能设置为0！');
                            return
                        }
                        this.TwelveToEighteen[index].discount = discount;
                        this.TwelveToEighteen[index].launch = 1
                    } else if(this.radio5 == 2){
                        this.TwelveToEighteen[index].launch = 1
                        this.TwelveToEighteen[index].discount = 100;
                    } else if(this.radio5 == 3){
                        this.TwelveToEighteen[index].launch = 0;
                        this.TwelveToEighteen[index].discount = 0;
                    }
                    if(this.radio4 === 1){
                        this.TwelveToEighteen.map(item=>{
                            if(item.discount === 0){
                                TwelveToEighteenStatus = 1
                            }
                        })
                    }
                } else if(name == 'EighteenToTwentyFour'){
                    if(this.radio5 == 1){
                        if(discount < 1){
                            this.$message.warning('折扣不能设置为0！');
                            return
                        }
                        this.EighteenToTwentyFour[index].discount = discount;
                        this.EighteenToTwentyFour[index].launch = 1
                    } else if(this.radio5 == 2){
                        this.EighteenToTwentyFour[index].launch = 1
                        this.EighteenToTwentyFour[index].discount = 100;
                    } else if(this.radio5 == 3){
                        this.EighteenToTwentyFour[index].launch = 0;
                        this.EighteenToTwentyFour[index].discount = 0;
                    }
                    if(this.radio4 === 1){
                        this.EighteenToTwentyFour.map(item=>{
                            if(item.discount === 0){
                                EighteenToTwentyFourStatus = 1
                            }
                        })
                    }
                }
                if(oneToSixStatus === 0 && sixToTwelveStatus === 0 && TwelveToEighteenStatus === 0 && EighteenToTwentyFourStatus === 0){
                    this.radio4 = 1
                } else {
                    this.radio4 = 2
                }
                this.clickFormData.show = false;
            },
            selectBudget(val){
                if(val == 2){
                    this.drawer = true
                }
            },
            closeDrawer2(){
                let list = [];
                this.oneToSix.map(item=>{
                    list.push(item)
                })
                this.sixToTwelve.map(item=>{
                    list.push(item)
                })
                this.TwelveToEighteen.map(item=>{
                    list.push(item)
                })
                this.EighteenToTwentyFour.map(item=>{
                    list.push(item)
                })
                let timeList = [];
                list.map(item=>{
                    if(item.launch === 1){
                        timeList.push(item)
                    }
                })
                if(timeList.length === 0){
                    this.$message.warning('时段设置至少设置一个！');
                    return;
                }
                let time_interval = JSON.stringify(list);
                let param = {
                    id:this.timeId,
                    time_interval:time_interval
                }
                this.$http.axiosPost(this.$api.editOne, param, res=>{
                    if(res.code === 200){
                        this.$message({
                            type:'success',
                            message:'修改成功！',
                            duration:1000,
                            onClose:()=>{
                                this.getSoreDrainage()
                            }
                        })
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
                this.clickFormData.show = false;
                this.drawer2 = false;
            },
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getSoreDrainage()
            },
            sourceCurrentChange(){},
            handleSelectionChange(val){
                // console.log(96,val)
                this.delectVal = val;
            },
            //修改推广计划名称
            clickName(row) {
                this.isShow = row.id;
                //点开输入框  聚焦
                setTimeout(()=> {
                    this.$refs[`nameInput_${row.id}`].focus();
                },100);
            },
            //失焦事件
            validateNum(val,id) {
                if (val === '' || val.trim().length === 0) {
                    this.$message.error('请填写推广计划名称');
                    return
                } else {
                    let param = {
                        id:id,
                        name:val
                    }
                    this.$http.axiosPost(this.$api.editOne, param, res=>{
                        if(res.code === 200){
                            this.$notify({
                                title: '成功！',
                                message: '修改成功',
                                type: 'success'
                            });
                            this.isShow = null;
                        } else {
                            this.$notify({
                                title: '失败',
                                message: res.msg,
                                type: 'warning'
                            });
                            this.isShow = null;
                        }
                    })
                }

            },
            inputTargetValue(row){
                // row.name = this.$refs.nameInput.value;
                // this.$refs.nameInput.style.display = 'none';
                // this.$refs.spanShow.style.display = 'block';
                // this.$notify({
                //     title: '',
                //     message: '修改成功！',
                //     type: 'success'
                // });
            },
            overPromotion(){
                if(this.delectVal.length < 1){
                    this.$message.warning('请选择要结束的推广！');
                    return
                }
                this.$confirm(`您确定要结束推广计划吗？`,'操作确认',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-red',
                }).then(()=>{
                    // let id = this.delectVal[i].id;
                    let arr=[]
                    this.delectVal.forEach(item=>{
                        arr.push(item.id)
                    })

                    let param = {
                        id:arr.join(',')
                    }
                    this.$http.axiosPost(this.$api.storeDrainageEnd, param,res=>{
                        if(res.code === 200){
                            console.log(res,"kkkkk");
                            this.$message({
                                type: 'success',
                                message: '结束成功!',
                                duration:1000,
                                onClose:()=>{
                                    this.getSoreDrainage();
                                    this.isEndExtend=true
                                }
                            });
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                    // for(let i = 0; i < this.delectVal.length; i++){
                    //     let id = this.delectVal[i].id;
                    //     let param = {
                    //         id:id
                    //     }
                    //     this.$http.axiosPost(this.$api.storeDrainageEnd, param, res=>{
                    //         if(res.code === 200){
                    //             this.$message({
                    //                 type: 'success',
                    //                 message: '结束成功!',
                    //                 duration:1000,
                    //                 onClose:()=>{
                    //                     this.getSoreDrainage();
                    //                 }
                    //             });
                    //         } else {
                    //             this.$message.warning(res.msg)
                    //         }
                    //     })
                    // }
                })
            },
            deleSelectData(){
                if(this.delectVal.length < 1){
                    this.$message.warning('请选择要删除的计划！');
                    return;
                }
                this.$confirm(`您确定要删除选中的${this.delectVal.length}个计划吗？`,'操作确认',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-red',
                    type: 'warning'
                }).then(()=>{
                    let list = [];
                    this.delectVal.map(item=>{
                        list.push(item.id)
                    })
                    let param = JSON.stringify(list);
                    this.$http.axiosPost(this.$api.storeDrainageDelete, {id:param}, res=>{
                        if(res.code === 200){
                            this.$message({
                                type: 'success',
                                message: '删除成功!',
                                duration:1000,
                                onClose:()=>{
                                    this.getSoreDrainage();
                                }
                            });
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            editData(id){
                this.$router.push({
                    path:'/student/operationpromotion/boothadvertisingtwo',
                    query:{
                        editId:id
                    }
                })
            },
            deleData(id){
                this.$confirm('您确定要删除该计划吗？','操作确认',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-red',
                    type: 'warning'
                }).then(()=>{
                     let list = [];
                     list.push(id)
                    let param = JSON.stringify(list);
                    this.$http.axiosPost(this.$api.storeDrainageDelete, {id:param}, res=>{
                        if(res.code === 200){
                            this.$message({
                                type: 'success',
                                message: '删除成功!',
                                duration:1000,
                                onClose:()=>{
                                    this.getSoreDrainage();
                                }
                            });
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })

                })
            },
            editBudget(id,budget){
                this.yusuan = null;
                this.yusuanIndex = null;
                this.yusuan = budget;
                this.yusuanIndex = id;
                this.drawer = true;
            },
            confirmBudget(){
                if(this.yusuan !== ''){
                    let param = {

                    }
                    if(this.yusuan === 0){
                        param = {
                            id:this.yusuanIndex,
                            budget:this.yusuan
                        };
                    } else {
                        param = {
                            id:this.yusuanIndex,
                            budget:this.yusuan,
                            is_budget:1
                        }
                    }
                    this.$http.axiosPost(this.$api.editOne, param, res=>{
                        if(res.code === 200){
                            this.$message({
                                type:'success',
                                message:'修改成功！',
                                duration:1000,
                                onClose:()=>{
                                    this.getSoreDrainage()
                                }
                            })
                            this.drawer = false;
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                } else {
                    this.$message.warning('不能为空！')
                }
            },
            editTimeDiscount(id){
                this.timeId = null;
                this.timeId = id;
                this.$http.axiosGetBy(this.$api.storeDrainage, {id:id}, res=>{
                    if(res.code === 200){
                        let resData = res.data;
                        let list2 = [];
                        resData.time_interval.map(item=>{
                            if(item.launch === 1){
                                list2.push(item)
                            }
                        })
                        if(list2.length < 24){
                            this.radio4 = 2;
                        }
                        let list = [];
                        this.oneToSix =  this.forTimeInterval(list,resData.time_interval,0,6);
                        this.sixToTwelve =  this.forTimeInterval(list,resData.time_interval,6,12);
                        this.TwelveToEighteen =  this.forTimeInterval(list,resData.time_interval,12,18);
                        this.EighteenToTwentyFour =  this.forTimeInterval(list,resData.time_interval,18,24);
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
                this.drawer2 = true;

            },
            forTimeInterval(arr,arrTotal,minIndex,maxIndex){
                arr=[];
                for(let i = minIndex; i < maxIndex; i++){
                    arr.push(arrTotal[i])
                }
                return arr;
            },
            clickDialog(item, evt) {
                if(item.launch === 0){
                    this.radio5 = 3
                } else {
                    if(item.discount != 100){
                        this.radio5 = 1
                    } else {
                        this.radio5 = 2;
                    }
                }
                let winClientWidth = document.body.clientWidth;
                this.closeHoverD();
                this.clickFormData.timezone = item.timezone;
                this.clickFormData.name = item.name;
                this.clickFormData.index = item.index;
                this.clickFormData.launch = item.launch;
                this.clickFormData.discount = item.discount;
                this.clickFormData.show = true;
                let x = evt.clientX + 20;
                let y = evt.clientY + 20;
                if(winClientWidth - 246 < x){
                    this.clickFormData.style = {
                        left: `${winClientWidth - 246}px`,
                        top: `${y}px`
                    }
                }
                else {
                    this.clickFormData.style = {
                        left: `${x}px`,
                        top: `${y}px`
                    }
                }
            },
            hoverDialog(item, evt) {
                let winClientWidth = document.body.clientWidth;
                this.hoverFormData.timezone = item.timezone;
                this.hoverFormData.discount = item.discount;
                this.hoverFormData.show = true;
                let x = evt.clientX + 4;
                let y = evt.clientY - 100;
                if(winClientWidth - 210 < x){
                    this.hoverFormData.style = {
                        left: `${winClientWidth - 210 }px`,
                        top: `${y}px`
                    }
                } else {
                    this.hoverFormData.style = {
                        left: `${x}px`,
                        top: `${y}px`
                    }
                }
            },
            closeHoverD() {
                this.hoverFormData.show = false;
            },
            selectShowWay(val){
                this.radio4 = val
                this.clickFormData.show = false;
                if(this.radio4 === 1){
                    this.oneToSix = this.forTimeSet2(this.oneToSix);
                    this.sixToTwelve = this.forTimeSet2(this.sixToTwelve);
                    this.TwelveToEighteen = this.forTimeSet2(this.TwelveToEighteen);
                    this.EighteenToTwentyFour = this.forTimeSet2(this.EighteenToTwentyFour);
                } else if(this.radio4 === 2){
                    this.oneToSix = this.forTimeSet(this.oneToSix);
                    this.sixToTwelve = this.forTimeSet(this.sixToTwelve);
                    this.TwelveToEighteen = this.forTimeSet(this.TwelveToEighteen);
                    this.EighteenToTwentyFour = this.forTimeSet(this.EighteenToTwentyFour);
                }
            },
            forTimeSet(arr){
                for(let i = 0; i < arr.length; i++){
                    arr[i].launch = 0;
                    arr[i].discount = 0;
                }
                return arr;
            },
            forTimeSet2(arr){
                for(let i = 0; i < arr.length; i++){
                    arr[i].launch = 1;
                    arr[i].discount = 100;
                }
                return arr;
            },
            // 点击切换
            changeTab(tab){
                this.tabType=tab.name


            },
            // 确认进入下一周期
            nextCycle(){

                if(!this.isEndExtend){
                    this.$message.warning('请先结束推广计划')
                }else{
                    // 进入到第二周期
                    this.$http.axiosPost(this.$api.storeDrainage_nextPeriod, {id:this.periodData[0].id},res=>{
                        console.log(res,"ppppp");
                        if(res.code==200){
                            this.amount=res.data.amount
                        }
                    })
                    console.log('this.cycleNumber==1',this.cycleNumber);
                    if(this.cycleNumber==1){
                        console.log('this.cycleNumber==1',this.cycleNumber,"ppp");
                        this.cycleNumber=2
                        localStorage.setItem('cycleNumber',2)
                        this.$message.success("操作成功")
                        // localStorage.setItem('oneTable',JSON.stringify(this.tableData))
                        this.periodData=[]
                        this.playGroup=[],
                        localStorage.setItem('playGroup',JSON.stringify([]))
                        this.isEndExtend=false
                        this.leftCount=3
                    }else{
                        if(this.cycleNumber==2){
                            this.cycleNumber=3
                            localStorage.setItem('cycleNumber',3)
                            this.$message.success("操作成功")
                            // localStorage.setItem('oneTable',JSON.stringify(this.tableData))
                            this.periodData=[]
                            this.playGroup=[],
                            localStorage.setItem('playGroup',JSON.stringify([]))
                            this.isEndExtend=false
                            this.leftCount=3
                    }
                    }
                     // 进入到第二周期
                    
                    

                }

            },
            // 新建计划组

            createPlanGroup(){
                this.planName=''
                this.planGroupVisible=true
            },
            closeDialogVisible(){
                this.planGroupVisible=false
            },
            confirmAddPlanGroup(){
                if(this.planName.trim()){
                    let index=this.playGroup.findIndex(item=>item.lable==this.planName.trim())
                    if(index!=-1){
                        this.$message.warning('名称已存在'); 
                    }
                    if(this.playGroup.length==4){
                        this.$message.warning('计划组数量不能超过4'); 
                    }
                
                    if(index==-1&& this.playGroup.length<4){
                        this.playGroup.push({
                            lable:this.planName.trim(),
                            value:this.planName.trim(),
                            disabled:false,
                            num:0
                        })
                        this.$message.success('创建计划组成功！')
                        localStorage.setItem('playGroup',JSON.stringify(this.playGroup))
                        this.planGroupVisible=false
                    }

                }else{
                    this.$message.warning("名称为必填项")
                }
            },
            selectPerio(e){
                  
                    this.periodData=[]
                    this.tableData.forEach(item=>{
                        if(item.period==e){
                            this.periodData.push(item)
                        }
                    })
}

        }
       
    }
</script>

<style scoped lang="scss">
    /*/deep/.pages-center{*/
    /*    display: flex;*/
    /*    justify-content: center;*/
    /*    margin-top: 24px;*/
    /*}*/
    /deep/.el-table--border::after, .el-table--group::after, .el-table::before{
        /*display: none;*/
    }
    .plan-name {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
        color: #1E63F1;
        .edit-plan-iocn {
            display: none;
            font-size: 14px;
            margin-left: 10px;
        }
        &:hover {
            color: #1E63F1;
            .edit-plan-iocn {
                display: inline-block;
                color: #333;
            }
        }
    }
    .name-input {
        display: block;
        position: absolute;
        top: 40px;
        height: 18px;
        width: 190px;
    }
    /deep/.el-table .el-table__body tr td{
                padding: 19px 0;
    }
    /deep/.el-breadcrumb .el-breadcrumb__item .el-breadcrumb__inner{
        color: #9A9A9A;
        font-size: 14px;
    }
    /deep/.el-button--default:hover{
        border-color: #FD4446;
        color: #FD4446;
        background: #ffffff;
    }
    /deep/.el-checkbox .is-checked .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.is-indeterminate .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.is-checked .el-radio__inner{
        border-color: #FD4446!important;
        background: #FD4446!important;
    }
    /deep/.el-radio__inner{
        width: 20px;
        height: 20px;
    }
    /deep/.is-checked .el-radio__label{
        color: #333333!important;
        font-size: 18px;
    }
    /deep/.el-radio .el-radio__label{
        color: #1E63F1;
        font-size: 18px;
    }
    .isShowActive{
        background: #FFECC8!important;
        border-color: #ffffff!important;
    }
    /deep/.el-tabs__nav-wrap::after{
        /*display: none;*/
        height: 1px;
    }
    /deep/.el-tabs__nav .is-active{
        color: #FD4446;
        font-weight: 400;
        font-size: 20px;
    }
    /deep/.el-tabs__active-bar{
        background-color: #FD4446;
    }
    /deep/.el-tabs__item{
        line-height: 30px;
    }
    .editIcon{
        display: inline-block;
        margin-left: 10px;
        font-size: 14px;
        cursor: pointer;
    }

    .BoothAdvertising{
        min-height: calc(100vh - 120px);
        /*height: 100%;*/
        background: #F7F9FB;
        .BoothAdvertisingHeader{
            span{
                font-size: 14px;
            }
        }
        .promotion-txt{
            display: flex;
            justify-content: space-between;
            color: #333333;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-size: 20px;
            font-weight: 500;
            line-height: 1;
            margin: 20px 0;
            span:nth-child(2){
                font-size: 18px;
                color: #FD4446;
                font-family: Avenir, Helvetica, Arial, sans-serif;
            }
            b{
                font-size: 12px;
                margin: 0 2px;
                font-weight: 500;
            }
        }
        .promotion-plan{
            height: calc(100vh - 270px);
            padding: 20px;
            background: #ffffff;
            .promotion-mid{
                .header{
                    display: flex;
                    justify-content: space-between;
                    .btn4{
                        background-color: rgb(64,158,255);
                        color: #ffffff;
                        border-color:rgb(64,158,255);
                    }

                }
                .tableBtn{
                    padding-top: 10px;
                    .btn1{
                        background: #FD4446;
                        color: #ffffff;
                        border-color:#FD4446;
                    }
                    .btn2{
                        margin-left: 10px;
                    }
                }
                .promotion-table{
                }
            }
        }
    }
    .CustomBudgetBox{
        display: flex;
        flex-direction: column;
        padding: 40px 60px;
        span{
            color: #989898;
            font-size: 18px;
            line-height: 1;
            margin-top: 20px;
        }
        .title{
            color: #333333;
            font-size: 24px;
            padding-bottom: 20px;
        }
        span:nth-child(4){
            line-height: 27px;
        }
        .box-bottom{
            margin-top: 30px;
            font-size: 18px;
            color: #333333;
        }

    }
    .hoverWrapper {
        padding: 10px 10px 10px 20px;
        z-index: 3302;
        position: fixed;
        top: 10px;
        left: 20px;
        width: 210px;
        /*height: 200px;*/
        background-color: #FFF;
        border: 1px solid #EEE;
        font-size: 18px;
        p{
            line-height: 27px;
        }
    }
    .clickWrapper{
        z-index: 3302;
        position: fixed;
        top: 10px;
        left: 20px;
        width: 246px;
        padding-top: 20px;
        background-color: #FFF;
        border: 1px solid #EEE;
        padding-left: 20px;
        font-size: 18px;
        padding-bottom: 30px;
        /deep/.el-radio{
            margin-top: 20px;
        }
        /deep/.el-radio-group{
            display: flex;
            flex-direction: column;
        }
        /deep/.el-radio__label{
            color: #333333!important;
            font-size: 18px;
        }
        .customInput{
            margin-top: 10px;
            display: flex;
            margin-left: 30px;
            padding-right: 34px;
            align-items: center;
            span{
                font-size: 18px;
            }
        }
        .clickWrapperBtn{
            margin-top: 20px;
            padding-left: 44px;
        }
    }
::v-deep .el-dialog__wrapper{
    top: 236px !important;
}
.noEnd{
    background-color: #73b4f9 !important;
    border: 1px solid #73b4f9!important;
}
</style>
